.join {
    width: 100%;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1920px;
    padding: 60px 80px 73px;
}

.join__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 64px;
    text-align: center;
    max-width: 1012px;
}

.join__content {
    width: 100%;
    display: grid;
    /* grid-template-columns: repeat(5, minMax(0, 1fr)); */
    grid-template-rows: max-content;
    column-gap: 19px;
    margin: 90px 0 0;
}

.join__content-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.join__card {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* transition: all 0.2s ease-in-out; */
}

.join__card-img {
    width: 100%;
    border-radius: 20px;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.join__card-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    width: 100%;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.join__card-author {
    width: 100%;
    display: grid;
    grid-template-columns: 40px 1fr;
    grid-template-rows: max-content;
    align-items: center;
    column-gap: 5px;
}

.join__card-author-img {
    width: 100%;
    border-radius: 50%;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    mask-image: -webkit-radial-gradient(white, black);
}

.join__card-author-name {
    font-family: "ALS Hauss";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

@media (max-width: 1115px) {
    .join {
        padding: 60px 24px 73px;
    }

    .join__title {
        font-size: 44px;
        font-style: normal;
        font-weight: 750;
        line-height: 120%;
        text-align: center;
        max-width: 1012px;
    }

}

@media (max-width: 700px) {
    .join {
        padding: 44px 12px 64px;
    }

    .join__card {

        gap: 8px;
    }

    .join__card-img {
        border-radius: 8px;
    }

    .join__content {
        column-gap: 8px;
        margin: 17px 0 0;
    }

    .join__content-column {
        gap: 8px;
    }

    .join__card-title {
        font-size: 8.644px;
        font-style: normal;
        font-weight: 700;
        line-height: 10.806px;
    }

    .join__card-author {
        grid-template-columns: 17px 1fr;
    }

    .join__card-author-img {
        width: 17px;
        height: 17px;
    }

    .join__card-author-name {
        font-size: 8.644px;
        font-style: normal;
        font-weight: 400;
        line-height: 10.806px;
    }

    .join__title {
        font-size: 28px;
        font-style: normal;
        font-weight: 750;
        line-height: 32px;
    }
}