.our-story-steps {
    position: relative;
    overflow: hidden;
    height: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding: 129px 242px; */
    box-sizing: border-box;
    width: 100%;
}

.our-story-steps__text-box {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 510px;
}

.our-story-steps_second .our-story-steps__text-box {
    max-width: 410px;
}

.our-story-steps__number {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45.143px;
    font-style: normal;
    font-weight: 900;
    line-height: 100%;
    width: 79px;
    aspect-ratio: 1/1;
    border: 1px solid var(--neutral-active);
    border-radius: 50%;
    color: var(--neutral-active);
}

.our-story-steps__title {
    font-size: 56px;
    font-style: normal;
    font-weight: 750;
    line-height: 64px;
    /* 114.286% */
}

.our-story-steps__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    /* 155.556% */
}

.our-story-steps_second .our-story-steps__subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    column-gap: 5px;
    display: grid;
    grid-template-columns: 28px 1fr;

}

.our-story-steps__img {
    position: absolute;
    top: -465px;
    left: 404px;
    width: 2214px;
    height: 1859.93px;
}

.our-story-steps__box {
    height: 840px;
    width: 100%;
}

.our-story-steps__swiper {
    width: 100%;
    height: 100%;

    display: flex;
}

.our-story-steps__slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 129px 142px;
    box-sizing: border-box;
}

.our-story-steps_first {
    display: block;
    position: relative;
    z-index: 4;

    width: 100%;
    max-width: 1200px;
}

.our-story-steps_first_img {
    overflow: hidden;
}

.our-story-steps_first-visible {
    display: none;
}

.our-story-steps_second {
    position: absolute;
    z-index: 1;
}

.our-story-steps__img_phone-1 {

    width: 282.548px;
    height: 568.914px;

}

.our-story-steps__img_phone-2 {
    top: -30px;
    width: 287.242px;
    height: 622.874px;
    flex-shrink: 0;
    left: 614px;
    z-index: 4;
}

.our-story-steps__img_phone-3 {
    width: 282.135px;
    height: 623.146px;
    top: -30px;
    left: 1014px;
    z-index: 4;
}

.our-story-steps__img_phone-4 {
    top: -30px;
    width: 287.242px;
    height: 622.874px;
    flex-shrink: 0;
    left: 467px;
    z-index: 3;
}

.our-story-steps__img_phone-5 {
    width: 282.135px;
    height: 623.146px;
    top: -30px;
    left: 1178px;
    z-index: 3;
}

.our-story-steps__img_ecomm {
    width: 476.211px;
    height: 663.447px;
    flex-shrink: 0;
    left: 800px;
    top: 100px;
}

.our-story-steps_third .our-story-steps__text-box {
    margin-top: 100px;
}

.our-story-steps_third .our-story-steps__img_ecomm-green {
    top: 420px;
    left: 1150px;
    width: 432.844px;
    height: 377.808px;
    flex-shrink: 0;
    flex-shrink: 0;
    z-index: 3;
}

.our-story-steps_third .our-story-steps__img_ecomm {
    margin-top: 10px;
    z-index: 2;
}

.our-story-steps_third .our-story-steps__img_ecomm-figure {
    top: 150px;
    left: 550px;
    width: 955.14px;
    height: 534.348px;
    z-index: 1;
}

.our-story-steps_second .mobile-first__screens-list {
    right: -300%;
    width: 300%;
    height: 100%;
}

.our-story-steps_second .mobile-first__screen-item {
    margin-left: -75px;
    z-index: 5;
}

.our-story-steps_second .mobile-first__screen-item:first-child {
    margin: 0;
    top: 0;
    z-index: 1;
    left: 80px;

}

.our-story-steps_second .mobile-first__screen-item:nth-child(2) {
    left: 30px;
}

.our-story-steps_second .mobile-first__screen-item:nth-child(4) {
    z-index: 2;
    right: 20px;
}

.our-story-steps_second .mobile-first__screen-item:nth-child(5) {
    z-index: 1;
    left: -155px;
}

.our-story-steps_second .mobile-first__screen-item {
    z-index: 4;
}

.our-story-steps_second .mobile-first__screen-item:last-child {
    margin: 0;
    top: 0;
}

@media (max-width:1200px) {
    .our-story-steps__slide {
        padding: 60px 60px;
    }

    .our-story-steps_first_img .our-story-steps__img {
        width: 1200px;
        height: 1000px;
        top: 100px;
    }

    .our-story-steps_third .our-story-steps__img_ecomm-figure {
        left: 437px;

    }

    .our-story-steps__img_ecomm {
        left: 640px;
    }
}

@media (max-width:960px) {
    .our-story-steps__slide {
        padding: 0;
    }

    .our-story-steps__swiper {
        /* height: 105vh;
        max-height: 720px; */
    }

    .our-story-steps_first_img .our-story-steps__img {
        width: 800px;
        height: 600px;
        top: 250px;
        left: 280px;
    }

    .our-story-steps {
        padding: 40px 0px;
        justify-content: flex-start;
        height: fit-content;
    }

    .our-story-steps_first, .our-story-steps_second, .our-story-steps_third {
        border-radius: 20px;
        border: 2px solid var(--primary-active);
        background: var(--website-secondary-background);
        height: 100vh;
        max-height: 680px;
        overflow: hidden;
        position: relative;
        width: 100%;
        padding: 39px 20px 0 20px;
        box-sizing: border-box;
        margin: 0 auto;
    }

    .our-story-steps__title {
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        line-height: 28px;
        /* 116.667% */
    }

    .our-story-steps__subtitle {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        /* 150% */
    }

    .our-story-steps__img {
        width: 529.621px;

        height: 568.91px;
        top: 55%;
        left: unset;
        right: -244px;
    }

    .our-story-steps__number {
        position: absolute;
        right: 20px;
        top: 20px;
        text-align: center;
        width: 51px;
        height: 51px;
        flex-shrink: 0;
        font-size: 29.143px;
        font-style: normal;
        font-weight: 900;
        line-height: 21.857px;
        /* 75% */
    }

    .our-story-steps__img_phone-1 {
        width: 134.059px;
        height: 271.922px;
        flex-shrink: 0;
        left: 87px;
        top: 55%;
    }

    .our-story-steps__img_phone-2 {
        width: 137.213px;
        height: 307.095px;
        flex-shrink: 0;
        left: 0;
        top: 399px;
    }

    .our-story-steps__img_phone-3 {
        width: 134.773px;
        height: 307.225px;
        flex-shrink: 0;
        left: 170px;
        top: 370px;
    }

    .our-story-steps__img_phone-4, .our-story-steps__img_phone-5 {
        display: none;
    }

    .our-story-steps_second .our-story-steps__subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 157.143% */
    }

    .our-story-steps_second .our-story-steps__text-box {
        gap: 9px;
    }

    .our-story-steps_third .our-story-steps__text-box {
        margin-top: 0;
    }

    .our-story-steps__img_ecomm {
        width: 325px;
        height: 452px;
        flex-shrink: 0;
        left: 20px;
        top: 155px;
    }

    .our-story-steps_third .our-story-steps__img_ecomm-figure {
        bottom: -42px;
        width: 491px;
        height: 252px;
        left: -42px;
        top: unset;
    }

    .our-story-steps_third .our-story-steps__img_ecomm-green {
        display: none;
    }

    .our-story-steps__title {
        width: 100%;
    }

    .our-story-steps_third .our-story-steps__title {
        max-width: 200px;
    }

    .our-story-steps_second .mobile-first__screens-list {
        height: unset;
        width: unset;
        right: unset;
    }

    .our-story-steps_second .mobile-first__screen-item:first-child {
        display: none;
    }

    .our-story-steps_second .mobile-first__screen-item:last-child {
        display: none;
    }

    .our-story-steps_second .mobile-first__screen-item {
        margin-left: -25px;
    }

    .our-story-steps_second .mobile-first__screens-list {
        top: 50%;
    }
}

@media (max-width:800px) {

    .our-story-steps_first_img .our-story-steps__img {
        width: 800px;
        height: 600px;
        top: 300px;
        left: 180px;
    }
}

@media (max-width:660px) {

    .our-story-steps_first_img .our-story-steps__img {
        width: 800px;
        height: 600px;
        top: 300px;
        left: 10px;
    }
}

@media (max-width:480px) {
    .our-story-steps_second .mobile-first__screens-list {
        top: 67%;
    }

    .our-story-steps_second .mobile-first__screen-item:nth-child(2) {
        top: -80px;
    }

    .our-story-steps_second .mobile-first__screen-item:nth-child(4) {
        top: -80px;
    }

    .our-story-steps_second .mobile-first__screen-item:nth-child(3) {
        top: -80px;
    }

    .our-story-steps_first_img .our-story-steps__img {
        width: 600px;
        height: 500px;
        top: 57%;
        left: 0px;
    }
}

@media (max-width:420px) {
    .our-story-steps_first_img .our-story-steps__img {}

    .our-story-steps_first .our-story-steps__subtitle:last-child {
        max-width: 100%;
    }
}