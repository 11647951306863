.operates {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1920px;
  padding: 80px 80px 50px;
}

.operates__title {
  font-size: 79.859px;
  font-style: normal;
  font-weight: 750;
  line-height: 85.667px;
  text-align: center;
  max-width: 1030px;
}

.operates__animation-block {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 35px;
}

.operates__video {
  width: 80%;
  object-fit: cover;
}

.operates__logos-block {
  width: 12%;
  height: 25.68%;
  position: absolute;
  left: 51.5%;
  top: 36.78%;
  overflow: hidden;
  transform: translate(-50%);
}

.operates__logos {
  opacity: 0;
  transform: translateY(150%);
  transition: transform 5s linear;
}

.operates__logos_started {
  opacity: 1;
  transform: translateY(100%);
  animation: line_run 10s linear 5s infinite;
}

.operates__logos-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.operates__logos-list_type_first-frame {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  transform: translateY(-100%);
}

.operates__logo-item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.operates__logo {
  width: 100%;
  object-fit: contain;
  user-select: none;
  pointer-events: none;
}

@keyframes line_run {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0%);
  }
}

@media (max-width: 1200px) {
  .operates__title {
    font-size: 64px;
    line-height: 120%;
    max-width: 976px;
  }
}

@media (max-width: 1000px) {
  .operates {
    padding: 50px 80px;
  }

  .operates__title {
    font-size: 44px;
    font-style: normal;
    font-weight: 750;
    line-height: 120%;
    max-width: 586px;
  }
}

@media (max-width: 880px) {
  .operates {
    padding: 51px 0 33px;
    overflow: hidden;
  }

  .operates__title {
    font-size: 38.917px;
    font-style: normal;
    font-weight: 750;
    line-height: 41.748px;
    padding: 0 35px;
    box-sizing: border-box;
  }

  .operates__video {
    width: 150%;
  }

  .operates__logos-block {
    width: 20%;
  }
}
