.header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 0;
    box-sizing: border-box;
    background-color: var(--website-background);
    position: sticky;
    transition: all 0.3s ease-in-out;
    z-index: 10;
}


.header_hide {
    top: -56px;
}

.header_show {
    top: 0;
}


.header__content {
    box-sizing: border-box;
    width: 100%;
    padding: 0 80px;
    max-width: 1920px;
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    grid-template-rows: max-content;
    column-gap: auto;
    align-items: center;

}

.header__logo-box {
    width: 88px;
    height: 32px;
}

.header__logo {
    width: 100%;
    height: 100%;
}

.header__logo_fixed {
    width: 88px;
    height: 32px;
    position: fixed;
    top: 12px;
    left: var(--logo-position);
}

.header__links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
}

.header__link {
    font-size: 16px;
    font-style: normal;
    font-weight: 750;
    line-height: 125%;
    color: var(--neutral-active);
    transition: all 0.2s linear;
}

.header__link_active {
    color: var(--primary-active);
}

.header__controls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

}

.header__login {
    font-size: 16px;
    font-style: normal;
    font-weight: 750;
    line-height: 125%;
    color: var(--neutral-active);
}

.header__control {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--website-secondary-background);
}

.header__control-icon {
    width: 14px;
    height: 14px;
}

.header__control-icon-fill {
    fill: var(--neutral-active);
}

.header__control-mobile-menu {
    display: none;
}

.header__control-mobile-menu-icon {
    width: 100%;
    height: 100%;
}

.header__control-mobile-menu-icon-stroke {
    stroke: var(--neutral-active);
}

@media (max-width: 1100px) {
    .header__content {
        padding: 0 24px;
    }
}

@media (max-width: 880px) {
    .header__links {
        display: none;
    }

    .header__content {
        display: flex;
        flex-direction: row;
        align-items: center;
        grid-template-columns: unset;
        grid-template-rows: unset;
        column-gap: unset;
        row-gap: unset;
        justify-content: space-between;
    }

    .header__control-mobile-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
    }

    .header__control{
        display: none;
    }
    .header__login{
        display: none;
    }
}