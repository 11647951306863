.select {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.select__value-box {
  width: 100%;
  height: 84px;
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  border: 2px solid var(--partners-second-card-border);
  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  padding: 0 18px 0 14px;
  background-color: transparent;
  color: var(--neutral-active);

  font-family: Inter;
  font-size: 26.756px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.867px;
  /* 171.429% */
}

.select__value-box__icon {
  position: absolute;
  top: 34px;
  right: 20px;
}

.select__value-icon {
  width: 35px;
  height: 35px;
}

.select__value {
  color: var(--neutral-active);
  /* opacity: 0.3; */
  font-family: "ALS Hauss";
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
  line-height: 171%;

  color: #AAA;
  font-family: Inter;
  font-size: 26.756px;
  font-style: normal;
  font-weight: 400;
  line-height: 45.867px;
  /* 171.429% */
}

.select__dropdown {
  width: 100%;
  position: absolute;
  max-height: 356px;
  overflow-y: scroll;
  top: calc(100% + 9px);
  left: 0px;
  border: 2px solid var(--neutral-active);
  background-color: var(--website-background);
  box-shadow: 0px 1px 10px 0px rgba(16, 24, 40, 0.22);
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  z-index: 10000;
  border-radius: 30px;
}

.select__dropdown_opened {
  visibility: visible;
  opacity: 1;
}

.select__dropdown-overlay {
  width: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
}

.select__dropdown_opened .select__dropdown-overlay {
  width: 100vw;
}

.select__dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 2px;
  z-index: 101;
}

.select__dropdown-arrow {
  width: 13px;
  height: 23.5px;
  position: absolute;
  top: 33px;
  right: -10px;
  display: none;
}

.select__dropdown-arrow-fill {
  fill: var(--website-background);
}

.select__dropdown-arrow-stroke {
  stroke: var(--neutral-active);
}

.select__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 10px;
  box-sizing: border-box;
}

.select__item-btn {
  width: 100%;
  height: 100%;
  min-height: 82px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  gap: 10px;
  border-radius: 17px;
  border: 2px solid var(--website-background);
  box-sizing: border-box;
  padding: 8px 8px 8px 18.7px;
  transition: all 0.2s ease-in-out;
}

.select__item-btn:hover {
  border-color: var(--select-dropdown-border-color);
}

.select__item-btn_selected {
  border-color: var(--primary-active);
  background-color: var(--primary-active);
  pointer-events: none;
  user-select: none;
}

.select__item-btn_selected .select__item-text {
  color: #fff;
}

/* .select__item{
  min-height: 82px;
} */
.select__item-icon {
  width: 35px;
  height: 35px;
}

.select__item-text {
  color: var(--Gray-500, #667085);
  font-family: 'Inter';
  font-size: 25.978px;
  font-style: normal;
  font-weight: 400;
  line-height: 44.533px;
  /* 171.429% */
}

@media (max-width: 780px) {
  .select__value-box {
    height: 55px;
    gap: 3px;
    border-radius: 50px;
    border: 1px solid var(--neutral-active);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    padding: 0 9px 0 9px;

    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 45.867px;
    /* 269.804% */
  }

  .select__value-box__icon {
    width: 17px;
    top: 35%;
    right: 11px;
  }

  .select__value-icon {
    width: 27px;
    height: 27px;
  }

  .select__value {
    font-size: 17px;
  }

  .select__dropdown {
    /* width: 90px; */
    /* top: 64px; */
    left: auto;
    border-radius: 19.535px;
    border: 1.302px solid #000;
  }

  .select__dropdown-arrow {
    width: 10px;
    height: 17px;
    transform: rotate(-90deg);
    top: -12px;
    right: auto;
  }

  .select__dropdown-arrow-stroke {
    stroke-width: 4px;
  }

  .select__list {
    gap: 0px;
  }

  .select__item-btn {
    min-height: 54px;
    max-height: 54px;
    gap: 6px;
    border-radius: 12px;
    border: 1px solid var(--website-background);
    padding: 12.305px 5.468px 12.09px 12.169px;
    box-sizing: border-box;
  }

  .select__item-btn:hover {
    border-color: var(--select-dropdown-border-color);
  }

  .select__item-btn_selected {
    border-color: var(--primary-active);
  }

  .select__item-icon {
    width: 22px;
    height: 22px;
  }

  .select__item-text {
    font-size: 14px;
  }
}

.select__dropdown {
  overflow: hidden;
  /* Скрывает скролл за пределами основного блока */
}

.select__dropdown_opened {
  visibility: visible;
  opacity: 1;
}

.select__dropdown-container {
  max-height: 300px;
  /* Максимальная высота выпадающего списка */
  overflow-y: auto;
  /* Скролл появляется внутри контейнера */
}

/* Стиль для скрытия стандартного скроллбара и стилизации только зоны прокрутки */
.select__dropdown-container::-webkit-scrollbar {
  width: 8px;
}

.select__dropdown-container::-webkit-scrollbar-track {
  background: transparent;
}

.select__dropdown-container::-webkit-scrollbar-thumb {
  background-color: var(--primary-active);
  border-radius: 4px;
}

.select__dropdown-container::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-hover);
}
